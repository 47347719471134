$theme-colors: (
  "primary": #801c77,
  "secondary" : yellow,
  "danger": #ff4136
);
$primary: #801c77;
$secondary: yellow;
@import "~bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "animate.scss";
@import "hover/hover.scss";
@import "photoswipe.css";

// @import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
// @import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah');
// @import url('https://fonts.googleapis.com/css?family=Amatic+SC');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700');
@import url('https://fonts.googleapis.com/css?family=Nothing+You+Could+Do');
@import url('https://fonts.googleapis.com/css?family=Rajdhani');
@import url("https://fonts.googleapis.com/css?family=Poller+One|Arima+Madurai");

@mixin responsiveImage {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

$border-radius: 5px;

$border : rgba($primary, 0.1);

body {
  overflow-x: hidden;
  // font-family: 'Rajdhani', sans-serif;
  font-family: "Arima Madurai", sans-serif;
  // margin-top: 70px;
}

@keyframes animate-bg {
  0% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1.3);
  }
}

.bg-primary {
  background: $primary;
}

.bg-secondary {
  background: $secondary;
}

.bg-grey-2 {
  background: rgba(black, 0.02);
}

.bg-grey-3 {
  background: rgba(black, 0.03);
}

.bg-grey-4 {
  background: rgba(black, 0.04);
}

.bg-grey-5 {
  background: rgba(black, 0.05);
}

.text-white-light {
  color: #EAEAEA;
}

.mobileMenu {
  // height: 50vh;
}

.text-white-light {
  color: rgba(white, 0.5);
}

.text-grey-7 {
  color: rgba(black, 0.7);
}

.text-grey-5 {
  color: rgba(black, 0.5);
}

.shadow-4 {
  box-shadow: 0px 10px 20px rgba(black, 0.1);
}

.break-word {
  word-break: break-word;
}


#home_banner {
  max-height: 85vh;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    height: 85vh;
    filter: blur(5px);
    transform: scale(1.3);
    animation: animate-bg 60s ease-in;
  }

  .home-banner-text {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-weight: 300;
    font-family: "Arima Madurai", sans-serif;

    .subtitle {
      width: 40vw;
      margin: 0 auto;
    }
  }





  @media (max-width: 768px) {
    max-height: 50vh;

    img {
      height: 50vh;
    }

    .home-banner-text {
      .subtitle {
        width: 80vw;
        margin: 0 auto;
      }
    }
  }
}

.main_logo {
  max-width: 150px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90px;
  }
}

.video{
  width: 50vw;
  margin: 0 auto;
  padding: 30px;

  iframe{
    border-radius: 10px;
    height: calc(50vw / 2);
    box-shadow: 0px 0px 30px rgba(black, 0.1);
  }
  @media (max-width: 1000px) {
    width: 100vw;
    iframe{
      height: calc(50vw / 1);
    }
  }
}

.person-photo {
  position: relative;
  height: 400px;

  img {
    clip-path: polygon(30%);
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(black, 0.1);
    height: 300px;
    width: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    filter: none;
  }

}

.title {
  position: relative;

  // animation: swing 5s infinite;
  // animation-direction: alternate;
  .titleLine {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    // bottom: 0;
    margin: 0 auto;
    width: 100px;
    // border-bottom: 3px dotted $primary;
    height: 1px;
    background: $primary;
    animation: zoomIn 2s infinite;
    animation-direction: alternate;
  }
}

.navbar {
  width: 100vw;
  // padding: 10px;
  color: white;
  background: #fff;
  // color: white;
  border-bottom: 1px solid $border;
}

.desktopMenuItems {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  a {
    font-weight: bold;
    display: inline-grid;
    text-decoration: none !important;
  }
}

.menuClose {
  cursor: pointer;
}

.menuBox {
  background: rgba(black, 0.9);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;

  // animation: slideInDown 1s;
  span {
    // position: absolute;
    // left: 0;
    // top: 0;
    // z-index: -1;
    // width: 100%;
    // height: 100%;
    // opacity: 0.5;
  }

  ul {
    color: white;
    list-style: none;
    margin-top: 20vh;

    @media (max-width: 768px) {
      margin-top: 4vh;
    }

    li {
      list-style: none;
      font-size: 2rem;
    }
  }

  a {
    color: white;
    visibility: hidden;

    &:hover {
      text-decoration: none;
    }
  }
}

.home_hero_image_container {
  width: 100vw;
  height: 75vh;
  margin: 0 auto;
  // margin-top: 10%;
  position: relative;
  transition: all 0.3s;

  .home_hero_image_box {
    position: absolute;
    right: 0;
    // top: -5%;
    background: $primary;
    color: white;
    height: 100%;
    width: 100%;
    transition: all 0.3s;
  }

  .home_hero_image {
    height: 100%;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    @include responsiveImage;
    transition: all 0.3s;
  }

  .home_hero_image_content_box {
    transition: all 0.3s;

    // position: absolute;
    // width: 50vw;
    // background: rgba($primary,1);
    // padding: 20px;
    // height: 100%;
    // left: -10%;
    // transform: skewX(-20deg);
    // text-align: right;
    // border-right: 2px solid white;
    // color: white;
    .home_hero_image_content {
      padding: 20px;
      margin-top: 40%;
      text-align: center;
      width: 70vw;
      overflow: hidden;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      animation: flipInX 1s;
      // border: 2px solid white;
      // background: rgba($primary,0.3);
      transition: all 0.3s;
      border-radius: $border-radius;

      h1 {
        // font-family: 'Nothing You Could Do', cursive;
      }

      @media (max-width: 768px) {
        width: 90%;
      }

    }
  }

  .home_hero_image_scroller {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100px;
    text-align: center;
    animation: fadeInDown 2s infinite;

    i {}
  }
}

.bgSegment {
  @include responsiveImage;
  position: relative;
}

.dimmer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.4);
  z-index: 0;
}

.fader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(white, 0.4);
  z-index: 0;
}

.serviceCard {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  text-align: left;
  background: #fff;

  &:hover {
    // animation: slideInUp 1s;
  }

  img {
    width: 100%;
    // position: absolute;
    // left:-10000%; right: -10000%; 
    // top: -10000%; bottom: -10000%;
    // margin: auto auto;
    // min-width: 1000%;
    // min-height: 1000%;
    // -webkit-transform:scale(0.1);
    // transform: scale(0.1);
  }

  .contentBox {
    overflow: auto;
  }

  .title {
    font-size: 1.3rem;
    font-weight: bold;
    // position: absolute;
    // top: 5%;
    // left: -5%;
  }

  .tag2 {
    font-size: 1.15rem;
  }

  .tag3 {
    font-size: 1rem;
  }

  .imageBox {
    position: relative;

    .tag {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 1rem;
      ;
    }
  }

  .btn {
    // position: absolute;
    right: 2%;
    bottom: 2%;
    font-weight: bold;
    // color: white;
  }
}

.service_Card {
  background: white;
  color: black;
  text-align: center;
  transition: all 0.3s;
  min-height: 350px;
  border: 1px solid rgba($secondary, 0.1);
  cursor: pointer;

  svg {
    color: $primary;
    transition: all 1s;
  }

  &:hover {
    background: $primary;
    color: white;

    svg {
      transform: translateY(-30%);
      color: white;
    }
  }
}

.testimonialCard {
  width: 100%;

  .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    @include responsiveImage;
  }

  p {}
}

.pageHeader {
  height: 30vh;
  background: $primary;
  @include responsiveImage;
  position: relative;
  overflow: hidden;

  .pageTitle {
    position: absolute;
    bottom: -40px;
    background: $primary;
    padding: 10px;
    // margin-top: 20%;
    display: inline-block;
  }

  .polygon {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 130%;
    height: 100px;
    background: white;
    transform: rotate(-2deg);
    z-index: 100;
  }
}

.imageSegment {
  width: 100%;
  position: relative;
  @include responsiveImage;
}

.getQuoteBox {
  min-height: 600px;
}

.typeform-widget {
  .overlay {
    display: none;
  }
}

.pageDarkHeader {
  // height: 100px;
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.enquiryForm {
  height: 1000px;
  overflow: hidden;
  position: relative;
  overflow-y: hidden;

  iframe {
    overflow-y: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.cardImage {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 400px;

  h4 {
    background: rgba($primary, 0.9);
    color: white;
    padding: 5px;
    position: absolute;
    top: 5%;
    left: -5%;

    @media only screen and (max-width: 760px) {
      position: absolute;
      // top: 50%;
      left: 10px;
      right: 0;
      margin: 0 auto;
      display: inline-table;
      width: inherit;
      // transform: translateY(-50%);
    }
  }
}

@keyframes projectImgAnime {
  0% {
    top: 0%;
  }

  50% {
    bottom: 0%;
  }

  100% {
    top: 0%;
  }
}

#projects,
#projectsHome {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(270px, auto);
  overflow: hidden;
  transition: all 0.4s;

  .project_card {
    display: inline-grid;
    transition: all 0.4s;

    &:nth-child(1n) {
      // grid-columns: 1/3;
    }

    .project_card_img {
      overflow: hidden;
      height: 150px;
      border-radius: 4px;
      position: relative;
      transition: all 0.4s;

      svg {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        color: $primary;
      }

      img {
        opacity: 0;
        border-radius: 4px;
        margin-top: 0%;
        width: 100%;
        // min-width: 100%;
        // min-height: 100%;
        box-shadow: 0px 0px 30px rgba(black, 0.1);
        transition: all 0.4s;

        // animation: projectImgAnime 10s infinite;
        &:hover {
          // animation: slideInUp 1s;
        }
      }
    }

  }
}

.contactFAB {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #25D366;
  color: white;
  text-align: center;
  position: fixed;
  left: 20px;
  bottom: 20px;
  box-shadow: 0px 10px 10px rgba(black, 0.1);
  padding: 8px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s;
  z-index: 99999;

  &:hover {
    transition: all 0.3s;
    transform: scale(1.2);
  }
}

@media only screen and (max-width: 800px) {

  #projects,
  #projectsHome {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {

  #projects,
  #projectsHome {
    grid-template-columns: repeat(1, 1fr);
  }
}

// @media (min-width: 768px) { ... }


//Sticky Cards
.card-big-shadow {
  max-width: 320px;
  margin: 0 auto;
  position: relative;
}

.coloured-cards .card {
  margin-top: 30px;
}

.card[data-radius="none"] {
  border-radius: 0px;
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}


.card[data-background="image"] .title,
.card[data-background="image"] .stats,
.card[data-background="image"] .category,
.card[data-background="image"] .description,
.card[data-background="image"] .content,
.card[data-background="image"] .card-footer,
.card[data-background="image"] small,
.card[data-background="image"] .content a,
.card[data-background="color"] .title,
.card[data-background="color"] .stats,
.card[data-background="color"] .category,
.card[data-background="color"] .description,
.card[data-background="color"] .content,
.card[data-background="color"] .card-footer,
.card[data-background="color"] small,
.card[data-background="color"] .content a {
  color: #FFFFFF;
}

.card.card-just-text .content {
  padding: 50px 65px;
  text-align: center;

  @media only screen and (max-width: 600px) {

    padding: 10px 15px;

  }
}

.card .content {
  padding: 20px 20px 10px 20px;
}

.card[data-color="blue"] .category {
  color: #7a9e9f;
}

.card .category,
.card .label {
  font-size: 14px;
  margin-bottom: 0px;
}

.card-big-shadow:before {
  background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: -12%;
  content: "";
  display: block;
  left: -12%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

h4,
.h4 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.2em;
}

h6,
.h6 {
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
}

.card .description {
  font-size: 16px;
  color: #66615b;
}

.content-card {
  margin-top: 30px;
}

a:hover,
a:focus {
  text-decoration: none;
}

/*======== COLORS ===========*/
.card[data-color="blue"] {
  background: #b8d8d8;
}

.card[data-color="blue"] .description {
  color: #506568;
}

.card[data-color="green"] {
  background: #d5e5a3;
}

.card[data-color="green"] .description {
  color: #60773d;
}

.card[data-color="green"] .category {
  color: #92ac56;
}

.card[data-color="yellow"] {
  background: #ffe28c;
}

.card[data-color="yellow"] .description {
  color: #b25825;
}

.card[data-color="yellow"] .category {
  color: #d88715;
}

.card[data-color="brown"] {
  background: #d6c1ab;
}

.card[data-color="brown"] .description {
  color: #75442e;
}

.card[data-color="brown"] .category {
  color: #a47e65;
}

.card[data-color="purple"] {
  background: #baa9ba;
}

.card[data-color="purple"] .description {
  color: #3a283d;
}

.card[data-color="purple"] .category {
  color: #5a283d;
}

.card[data-color="orange"] {
  background: #ff8f5e;
}

.card[data-color="orange"] .description {
  color: #772510;
}

.card[data-color="orange"] .category {
  color: #e95e37;
}


.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
  transition: all 0.4s;
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 200;
  // color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.card-5 {
  background: radial-gradient(#f588d8, #c0a3e5);
}

.pattern-card {
  // background-color: #faf4f4;
  // opacity: 1;
  // background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%), linear-gradient(225deg, #ffffff 25%, transparent 25%), linear-gradient(45deg, #ffffff 25%, transparent 25%), linear-gradient(315deg, #ffffff 25%, #faf4f4 25%);
  // background-position: 12px 0, 12px 0, 0 0, 0 0;
  // background-size: 24px 24px;
  // background-repeat: repeat;
  outline: none;
  background-color: #e8e8e8;
  opacity: 1;
  background: linear-gradient(135deg, #ffffff55 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(225deg, #ffffff 25%, transparent 25%) -37px 0/ 74px 74px, linear-gradient(315deg, #ffffff55 25%, transparent 25%) 0px 0/ 74px 74px, linear-gradient(45deg, #ffffff 25%, #e8e8e8 25%) 0px 0/ 74px 74px;
  // background-color: #556;
  // background-image: linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
  //   linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
  //   linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
  //   linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
  //   linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a),
  //   linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a);
  // background-size: 80px 140px;
  // background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

//Timeline

main {
  min-width: 300px;
  max-width: 700px;
  margin: auto;

  p {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    border-image: linear-gradient(to right, $primary 0%, #d53a9d 100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px;
    counter-increment: section;
    position: relative;
    color: $black;

    //numbers
    &:before {
      content: counter(section);
      height: 20px;
      width: 20px;
      text-align: center;
      color: rgb(255 255 255);
      background-color: $primary;
      border-radius: 50%;
      padding: 7px;
      font-size: 12px;
      line-height: 8px;
      position: absolute;
    }
  }

  //odd number borders
  p:nth-child(odd) {
    border-right: 3px solid;
    padding-left: 0;
    text-align: right;

    &:before {
      left: 100%;
      margin-left: -10px;
    }
  }

  //even number borders
  p:nth-child(even) {
    border-left: 3px solid;
    padding-right: 0;

    &:before {
      right: 100%;
      margin-right: -10px;
    }
  }

  //handle first and last
  p:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  p:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

//Block Quote
.blockquote-wrapper {
  display: flex;
  // height: 100vh;
  padding: 0 10px;
}

/* Blockquote main style */
.blockquote {
  position: relative;
  max-width: 700px;
  margin: 30px auto;
  align-self: center;
}

/* Blockquote header */
.blockquote h1 {
  position: relative;
  /* for pseudos */
  color: $primary;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  border: 2px solid $primary;
  border: solid 2px;
  border-radius: 20px;
  padding: 25px;
  // background-color: $primary;
}

/* Blockquote right double quotes */
.blockquote h1:after {
  content: "";
  position: absolute;
  border: 2px solid $primary;
  border-radius: 0 50px 0 0;
  width: 60px;
  height: 60px;
  bottom: -62px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 3;
}

.blockquote h1:before {
  content: "";
  position: absolute;
  width: 80px;
  border: 6px solid $primary;
  bottom: -3px;
  left: 50px;
  z-index: 2;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
  .blockquote h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

}

/* Blockquote subheader */
.blockquote h4 {
  position: relative;
  color: $primary;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding-top: 15px;
  z-index: 1;
  margin-left: 120px;
  padding-left: 12px;
}


.blockquote h4:first-letter {
  margin-left: -12px;
}

.gallery{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  // padding: 10px;
  a{
    width: 100%;
    // width: calc(20vw - 40px);
    height: calc(20vw /2);
    border-radius: 10px;
    float: left;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 10px 20px rgba(black, 0.1);

    @media (max-width: 1000px) {
      height: calc(10vw / 0.3);

    }
  }

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  // width: 200px;
  // height: 200px;

  background-size: cover;
  background-position: 50% 50%;

  text-indent: -300px;
  overflowidth: hidden;
}

.media-card{
  width: 50vw;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 100%;
  }
}